<template>
  <div>
    <h2>求人票</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        テキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <AlphaInfo :data-dic="dataDic" />
    </b-card>
    <b-card
      title=""
    >
      <BetaInfo :data-dic="dataDic" />
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BAlert,
} from 'bootstrap-vue'
import menulist from '@/components/conf/JPCAREER様_就労PF_求人企業_求人情報.json'
import AlphaInfo from '@/components/company/jobOpenings/AlphaInfo.vue'
import BetaInfo from '@/components/company/jobOpenings/BetaInfo.vue'

export default {
  components: {
    BCard,
    BAlert,
    AlphaInfo,
    BetaInfo,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
    }
  },
  methods: {
    edit() {
      this.$router.push({ path: '/job-jobapplicant-edit', query: { met: 'edit', id: this.$route.query.id } })
    },
    cancel() {
      this.$router.push({ name: 'job-jobapplicant-list' })
    },
  },
}
</script>
