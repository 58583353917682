<template>
  <div>
    <h2>求人職種情報</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-card
      title=""
    >
      <div
        v-for="(item,index) in menulist"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <hr>
        <b-row>
          <b-col lg="6">
            <h5
              class="text-capitalize mb-75"
            >
              {{ item.label }}
            </h5>
          </b-col>
          <b-col lg="6">
            <b-card-text>
              <div
                v-if="dataDic[item.key]"
              >
                <div
                  v-if="item.type === 'selectSingle'"
                >
                  {{ dataDic[item.key]['labelName'] }}
                </div>
                <div
                  v-else
                >
                  {{ dataDic[item.key] }}
                </div>
              </div>
              <div
                v-else
              >
                登録なし
              </div>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-md="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
          >
            戻る
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="edit"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BCardText,
} from 'bootstrap-vue'
import menulist from '@/components/conf/JPCAREER様_就労PF_求人企業_新規作成1_その他情報.json'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
    }
  },
  methods: {
    edit() {
      let met = ''
      if (this.$route.query.met === 'create') {
        met = 'create'
      } else {
        met = 'edit'
      }
      this.$router.push({
        path: '/job-jobapplicant-edit',
        query: {
          met,
          id: typeof this.$route.query.id !== 'undefined' ? this.$route.query.id : '',
          type: 'beta',
        },
      })
    },
    cancel() {
      this.$router.push({ name: 'job-jobapplicant-list' })
    },
  },
}
</script>
