<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmJobOpenings :data-dic="dataDic" />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmJobOpenings from '@/components/company/jobOpenings/ConfirmJobOpenings.vue'
import { mapGetters } from 'vuex'
import Sidebar from '@/components/company/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmJobOpenings,
    Sidebar,
  },
  data() {
    return {
      dataDic: {},
    }
  },
  computed: {
    ...mapGetters('jobApplicantBasic', ['jobOpeningsList']),
  },
  mounted() {
    if (this.jobOpeningsList !== 'undefined') {
      const index = this.jobOpeningsList.findIndex(v => v.docID === this.$route.query.id)
      this.dataDic = this.jobOpeningsList[index]
    }
  },
}
</script>

<style lang="scss">
</style>
